import { DirectUpload } from "activestorage";
import Timecode from "smpte-timecode";
import {
  convertBlobToBase64,
  downloadURI,
  generateDOCXTranscriptionFile,
  generateCSVTranscriptionFile,
  generateTXTTranscriptionFile,
} from "../utils";
import { v4 as uuidv4 } from "uuid";
import CommonMixin from "./common.mixin";
import axios from "axios";

const sleep = (seconds = 1) => new Promise((resolve) => setTimeout(resolve, seconds * 1000));

const getCSRFToken = () => $("meta[name=csrf-token]").attr("content");

const uuid = () => uuidv4();

export const uploadFile = (file, progress) => {
  const url = $("meta[name=upload-url]").attr("content");
  const upload = new DirectUpload(file, url, {
    directUploadWillStoreFileWithXHR: (request) => {
      request.upload.addEventListener("progress", (event) => {
        progress && progress(parseFloat((100 * (event.loaded / (event.total || event.totalSize))).toFixed(2)));
      });
    },
  });
  return new Promise((resolve) => {
    upload.create((error, blob) => {
      resolve({
        error,
        blob,
      });
    });
  });
};

export const fileReader = (file) =>
  new Promise((resolve) => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      resolve(null);
    }
  });

const objToFormData = (data, prefix = "", f = null) => {
  if (!f) {
    f = new FormData();
  }
  Object.keys(data).forEach((key) => {
    const value = data[key];
    const formKey = prefix ? `${prefix}[${key}]` : key;

    if (value !== null && typeof value === "object" && !Array.isArray(value)) {
      objToFormData(value, formKey, f); // Recursively handle nested objects
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === "object") {
          objToFormData(item, `${formKey}[${index}]`, f); // Handle nested objects inside arrays
        } else {
          f.append(`${formKey}[${index}]`, item); // Append array values
        }
      });
    } else {
      f.append(formKey, value); // Append non-object values
    }
  });

  return f;
};

export const downloadWorkbook = async (workbook, filename = "report.xlsx") => {
  const file = new File(
    [
      new Blob([await workbook.xlsx.writeBuffer()], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
    ],
    filename
  );
  downloadURI(URL.createObjectURL(file), filename);
  return file;
};

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

export const base64ToFile = (image, filename) => {
  // Convert data URL to Blob
  const byteString = atob(image.split(",")[1]);
  const mimeType = image.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const b = new Blob([arrayBuffer], { type: mimeType });

  // Create a File object from the Blob
  return new File([b], filename, { type: mimeType });
};

export const fetchImg = async ({ url, index, ...data }) =>
  url
    ? await fetch(url, {
        cache: "no-cache",
      })
        .then((r) => r.blob())
        .then(convertBlobToBase64)
        .then((base64) => ({
          ...data,
          index: index,
          data: base64,
        }))
    : { index };

const railsToJSObj = (str) => JSON.parse(`${str.replaceAll(":", "").replaceAll("=>", ":")}`);

const inspectRoute = (isLoggedIn, routeName, callback) => {
  console.log("InspectRoute");
  // login - true -> redirect
  // login - false -> NO
  // project - true -> NO
  // project - false -> redirect

  if (routeName) {
    console.log(74);
    if (routeName === "auth") {
      callback("auth.login");
      return;
    }
    if (routeName.includes("auth") && isLoggedIn) {
      console.log(80);
      // if auth route and user is logged in then redirect to projects list
      callback("projects.index");
      return;
    }
    if (!routeName.includes("auth") && !isLoggedIn) {
      console.log(86);
      // if not auth route and not logged then redirect to login
      callback("auth.login");
      return;
    }
    console.log(91);
    callback(null);
  } else {
    console.log(94, isLoggedIn);
    if (isLoggedIn) {
      callback("projects.index");
    } else {
      callback("auth.login");
    }
  }
};

// const generateCSVTakeLogFile = (take, projectName) => {
//   let fileInput = [];

//   fileInput.push(`Project Name: ${projectName}`);
//   fileInput.push("\n\n");

//   fileInput.push(`File Name: ${take.name}`);
//   fileInput.push("\n\n\n");

//   fileInput.push(["Timecode", "Category", "User", "Comment"]);
//   fileInput.push("\n");

//   // Function to recursively process replies
//   const processReplies = (replies) => {
//     replies.forEach((reply) => {
//       // Add reply to CSV
//       fileInput.push(
//         `${reply.start_timecode},Reply,${reply.user_name},${reply.comment}`
//       );
//       fileInput.push("\n");

//       // Recursively process further replies
//       if (reply.replies.length > 0) {
//         processReplies(reply.replies);
//       }
//     });
//   };

//   take.logs.forEach((log) => {
//     fileInput.push(
//       `${log.start_timecode},Comment,${log.user_name},${log.comment}`
//     );
//     fileInput.push("\n");
//       if (log.replies.length > 0) {
//         processReplies(log.replies);
//       }
//   });

//   console.log(fileInput)
//   let blob = new Blob(fileInput, { type: "text/csv" });

//   downloadURI(URL.createObjectURL(blob), take.name + "_log" + ".csv");
// };

const generateCSVTakeLogFile = (take, projectName) => {
  const csvRows = [];
  // Add project and file name information
  csvRows.push(`Project Name: ${projectName}`);
  csvRows.push("\n");
  csvRows.push(`File Name: ${take.name}`);
  csvRows.push("\n\n\n");

  // Add CSV header
  csvRows.push("Timecode,Category,User,Comment");
  csvRows.push("\n");

  //   let previousTimecode = null;

  // Function to add an entry to the CSV rows
  const addEntryToCSV = (entry) => {
    const { timecode, category, user, comment } = entry;
    // const timecodeField = previousTimecode === timecode ? "" : timecode;

    csvRows.push(`${timecode},${category},${user},${comment}`);
    csvRows.push("\n");
    // previousTimecode = timecode;
  };

  const processReplies = (replies) => {
    replies.forEach((reply) => {
      //   Add reply to CSV
      const replyEntry = {
        timecode: reply.start_timecode,
        category: "Reply",
        user: reply.user_name,
        comment: reply.comment,
      };
      addEntryToCSV(replyEntry);

      // Recursively process further replies
      if (reply.replies.length > 0) {
        processReplies(reply.replies);
      }
    });
  };

  // Iterate over logs
  take.logs.forEach((log) => {
    // Add comment entry
    const commentEntry = {
      timecode: log.start_timecode,
      category: "Comment",
      user: log.user_name,
      comment: log.comment,
    };
    addEntryToCSV(commentEntry);

    // Add reply entries
    if (log.replies.length > 0) {
      processReplies(log.replies, log.start_timecode);
    }
  });

  // Join CSV rows with newline character
  const csvContent = csvRows.join("");
  // Create a Blob object with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv" });
  // Trigger download
  downloadURI(URL.createObjectURL(blob), `${take.name}_log.csv`);
};

export const getIcon = (icon) => {
  try {
    return require(`./assets/svg/img/${icon}`);
  } catch (e) {
    console.error(`Icon not found: ${icon}`, e);
    //return require('@/assets/icons/default.svg');
    return null;
  }
};

const useKeysFromObj = (obj, keys = []) => {
  return Object.keys(CommonMixin.computed)
    .filter((key) => keys.includes(key))
    .reduce((obj, key) => {
      obj[key] = CommonMixin.computed[key];
      return obj;
    }, {});
};

const loadJs = (url) =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });

const loadPDFJs = async () => {
  await loadJs(/* webpackIgnore: true */ "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js");
  pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";
};

const readFileAsArrayBuffer = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });

const fileURLtoActiveStorageBlob = async ({ url, filename, content_type }) => {
  const response = await axios.post("/en/direct_uploads/file_url_to_blob", {
    authenticity_token: getCSRFToken(),
    blob: {
      url,
      filename,
      content_type,
    },
  });
  return response.data;
};

export const updateNestedValue = (obj, keyString, newValue) => {
  keyString
    .split(".")
    .reduce((acc, key, index, array) => (acc[key] = index === array.length - 1 ? newValue : acc[key] || {}), obj);
};

const SuiteAppUtils = {
  inspectRoute,
  IS_DEV: !window.location.host.includes("app"),
  sleep,
  uploadFile,
  objToFormData,
  downloadWorkbook,
  cloneObj,
  railsToJSObj,
  fileReader,
  fetchImg,
  base64ToFile,
  downloadURI,
  generateDOCXTranscriptionFile,
  generateCSVTranscriptionFile,
  generateTXTTranscriptionFile,
  generateCSVTakeLogFile,
  getIcon,
  useKeysFromObj,
  loadJs,
  loadPDFJs,
  readFileAsArrayBuffer,
  fileURLtoActiveStorageBlob,
};

export default SuiteAppUtils;
